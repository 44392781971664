import React, { useMemo } from "react";
import { useDatasources } from "../../../contexts/StoryblokContext/StoryblokContext";
import Enrich from "../../../Common/services/TextEnricher";
import { formatDateRange } from "../../../Common/Helpers/DateHelper";
import { LabelKey } from "../../../Common/StoryblokTypes";
import { FormattedMoney } from "../../../Common/Helpers/MoneyHelper";
import { getWeekLabel } from "../../../Common/getWeekLabel";
import { useSessionContext } from "../../../contexts/SessionContext/SessionContext";
import { useQuoteContext } from "../../../contexts/QuoteContext/QuoteContext";
import { PaymentDetails } from "../../../Common/Helpers/PaymentHelper";
import { Money, PaymentStatus, Quote } from "../../../Common/Types";
import TBCardStatus, { TBCardStatusAlignment, TBCardStatusPosition } from "../../BaseComponents/TBCardStatus";
import InfoCard from "../../BaseComponents/InfoCard";
import { getDestinationName, getQuoteInformation } from "../../../Common/services/QuoteService";
import TitleValueCell from "../../BaseComponents/TitleValueCell";

interface PaymentSummaryCardProps {
  quote: Quote;
  reservationPrice?: Money;
  paymentDetails: PaymentDetails;
  totalAmount: number;
  paid: Money | null;
}

const PaymentSummaryCard: React.FC<PaymentSummaryCardProps> = ({
  quote,
  reservationPrice,
  paymentDetails,
  totalAmount,
  paid,
}) => {
  const { language } = useSessionContext();
  const { labels } = useDatasources();
  const { quotesContentCache, isQuotesDestinationsLoading } = useQuoteContext();
  const { courseSegments, startAt, endAt, totalDuration } = getQuoteInformation(quote);

  const weeksLabel = getWeekLabel(labels, totalDuration);

  const reservationPriceAmount = reservationPrice?.amount;
  const summaryLineAmount =
    paymentDetails.status === PaymentStatus.ReservationSuccess && reservationPrice
      ? FormattedMoney(quote.prices.totalPrice)
      : paid
        ? FormattedMoney(paid)
        : "";

  const remainingAmount = useMemo(
    () => totalAmount - (paid?.amount ?? reservationPriceAmount ?? 0),
    [totalAmount, paid, reservationPriceAmount]
  );

  const destinationName = useMemo(
    () =>
      !isQuotesDestinationsLoading && courseSegments.length
        ? getDestinationName(courseSegments, quotesContentCache?.destinations)
        : "",
    [isQuotesDestinationsLoading, courseSegments]
  );

  const quoteCurrency = useMemo(() => quote.prices.totalPrice.currency ?? "", [quote]);
  const amountLabel = paid ? labels[LabelKey.remaining] : labels[LabelKey.total];
  const amountMoney: Money = {
    amount: paid ? remainingAmount : totalAmount,
    currency: paid ? paid.currency : quoteCurrency,
  };

  return (
    <div className="flex flex-col" data-testid="payment-summary-card">
      <InfoCard
        summary={labels[LabelKey.summary]}
        destinationName={destinationName}
        contentText={`${formatDateRange(startAt, endAt, language)} | ${Enrich(weeksLabel, { numWeeks: totalDuration })}`}
        amountLabel={amountLabel}
        amount={amountMoney}
        bottomChildren={
          <TBCardStatus
            position={TBCardStatusPosition.UnderCard}
            alignment={TBCardStatusAlignment.Center}
            variant={paymentDetails.color}
            testId="psc-summary-line"
            onClick={
              paymentDetails.status === PaymentStatus.ReservationSuccess
                ? () => (window.location.href = `/payment/${quote.id}`)
                : undefined
            }
          >
            <TitleValueCell
              leftTextValue={paymentDetails.statusText}
              leftTextVariant="label-lg-bold"
              rightTextValue={paymentDetails.status == null ? "" : summaryLineAmount}
              rightTextVariant="label-lg-bold"
              testId="psc-summary-line-status"
            />
          </TBCardStatus>
        }
      />
    </div>
  );
};

export default PaymentSummaryCard;
